export const data = {
  officeMembers: [
    {
      id: 1,
      name: "MABROUK Kacem",
      role: "Président",
      img: require("./images/Kacem.png").default,
      LinkedInLink: "https://www.linkedin.com/in/kacem-mabrouk-316553195/",
      fbLink: "https://www.facebook.com/kacem.mabrouk.54",
      InstaLink: "https://www.instagram.com/kacem.mabrouk/",
    },
    {
      id: 2,
      name: "ABD EL RAZZAK Malèk",
      role: "VPFD",
      img: require("./images/malek.png").default,
      fbLink: "https://www.facebook.com/salia.mabrouk.1",
      InstaLink: "https://www.instagram.com/malek_abderrazak/",
      bg: "#e608c8",
    },
    {
      id: 3,
      name: "SELMENE Faten",
      role: "Secrétaire Générale",
      img: require("./images/faten.png").default,
      fbLink: "https://www.facebook.com/profile.php?id=100009125272468",
    },
    {
      id: 4,
      name: "MABROUK Hedil",
      role: "VPPRE",
      img: require("./images/hedil.png").default,
      LinkedInLink: "https://www.linkedin.com/in/hedil-m-9043b7173/",
      fbLink: "https://www.facebook.com/hedil4829",
      InstaLink: "https://www.instagram.com/_h.e.d.i.l/",
    },
    {
      id: 5,
      name: "Dhia Eddine Abdou",
      img: require("./images/dhia.png").default,
      role: "Conseiller juridique",
      fbLink: "https://www.facebook.com/sahlyano.dhia",
    },
  ],
  advisors: [
    {
      id: 1,
      img: require("./images/rayen.png").default,
      name: "Rayen Mehrez",
      job: "Conseiller Media",
      role: "Conseiller Media",
      LinkedInLink:
        "https://www.linkedin.com/in/rayenmehrez/?fbclid=IwAR1lJhw5TmyHzHsESilEjJJS5i4sHTP6LKfOQMJk6B2J8yLEecRvqbTiOEA",
      fbLink: "https://www.facebook.com/rayenmehrez21",
      InstaLink:
        "https://www.instagram.com/rayen_mhz/?fbclid=IwAR3c1RHuck7AxKGxUc6JF1PNTYOW7SptJTugxkvEDiAnaMVdmiMCu7KgNKc",
    },
    {
      id: 2,
      img: require("./images/oumayma.png").default,
      name: "Oumaima Abd El Razzak",
      role: "Conseillère Thème National",
      fbLink: "https://www.facebook.com/oumaima.abderrazak.0101",
    },
    {
      id: 3,
      img: require("./images/iheb.png").default,
      name: "Iheb Boughraira ",
      role: "Conseiller Protocol  ",
      fbLink: "https://www.facebook.com/sahlyano.iheb",
    },
    {
      id: 4,
      img: require("./images/hazem.png").default,
      name: "Hazem Boughraira",
      role: "Conseiller dossier sponsoring",
      LinkedInLink: "https://www.linkedin.com/in/hazem-boughraira-099b7415a/",
      fbLink: "https://www.facebook.com/profile.php?id=100009239948364",
    },
    {
      id: 5,
      img: require("./images/badia.png").default,
      name: "Badia Cherif",
      role: "Conseiller créations et innovations",
      fbLink: "https://www.facebook.com/profile.php?id=100077561581040",
    },
    {
      id: 6,
      img: require("./images/salma.png").default,
      name: "Salma Ladjimi",
      role: "Conseillère 100% Efficacité",
      fbLink: "https://www.facebook.com/salma.ld.585",
    },
  ],
  members: [
    {
      id: 1,
      name: "Rihem Naiji",
      role: "Membre",
      img: require("./images/rihem.png").default,
      fbLink: "https://www.facebook.com/profile.php?id=100009203564472",
    },
    {
      id: 2,
      name: "Adham Naija",
      role: "Membre",
      img: require("./images/adhem.png").default,
      fbLink: "https://www.facebook.com/adham.naiji.3",
    },
    {
      id: 3,
      name: "Nejmeddine Ferjan",
      img: require("./images/naj.png").default,
      role: "Membre",
      fbLink: "https://www.facebook.com/ne.jme.7",
    },
  ],
};
